import { BglLogger } from '@bgl/lib-ts-integration';
import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import eventBus, { EventName } from "../eventbus/eventbus.ts";
import { beneficiaryApi } from './api/beneficiaryApiRtk';
import { contractInfoApi } from './api/contractInfoApiRtk';
import { customerApi } from './api/customerApiRtk';
import { customerAuthentApi } from './api/customerAuthentApiRtk.ts';
import { domiciliationApi } from './api/directDebitsApiRtk.ts';
import { documentAccessApi } from './api/documentAccessApiRtk';
import { featureApi } from './api/featureApiRtk';
import { gclApi } from './api/gclApiRtk';
import { ihubApi } from './api/ihubApiRtk';
import { messagingApi } from './api/messagingApiRtk';
import { notificationApi } from "./api/notificationApiRtk.ts";
import { paymentProApi } from './api/paymentProApiRtk.ts';
import { pingApi } from './api/pingApiRtk';
import { publicContactApi } from './api/publicContactApiRtk';
import { selfAdminApi } from './api/selfAdminApiRtk';
import { selfCareCardApi } from './api/selfCareCardApiRtk.ts';
import { webProContractApi } from './api/webProContractApiRtk';
import { reducer as beneficiaryReducer } from './datastore/features/beneficiary/storeBeneficiary';
import { reducer as gclReducer } from './datastore/features/gcl/storeGcl';
import { reducer as singlePaymentReducer } from './datastore/features/payment/storeSinglePayment';
import { reducer as standingOrderReducer } from './datastore/features/payment/storeStandingOrder.ts';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        BglLogger.getInstance().warn(`We got a rejected action ${JSON.stringify(action)} !`);
        if ((action?.payload as any)?.data?.errorCode === 'error_login') {
            eventBus.emit(EventName.DISCONNECTED, {
                popup: (action?.payload as any)?.url !== '/business_webmsg/rest/v1/ping'
            });
        }
    }

    return next(action);
};

export const store = configureStore({
    reducer: {

        gcl: gclReducer,
        beneficiary: beneficiaryReducer,
        singlePayment: singlePaymentReducer,
        standingOrder: standingOrderReducer,

        [customerAuthentApi.reducerPath]: customerAuthentApi.reducer,
        [pingApi.reducerPath]: pingApi.reducer,
        [gclApi.reducerPath]: gclApi.reducer,
        [beneficiaryApi.reducerPath]: beneficiaryApi.reducer,

        [featureApi.reducerPath]: featureApi.reducer,
        [selfCareCardApi.reducerPath]: selfCareCardApi.reducer,
        [contractInfoApi.reducerPath]: contractInfoApi.reducer,
        [customerApi.reducerPath]: customerApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [paymentProApi.reducerPath]: paymentProApi.reducer,
        [webProContractApi.reducerPath]: webProContractApi.reducer,
        [messagingApi.reducerPath]: messagingApi.reducer,
        [documentAccessApi.reducerPath]: documentAccessApi.reducer,
        [publicContactApi.reducerPath]: publicContactApi.reducer,
        [ihubApi.reducerPath]: ihubApi.reducer,
        [selfAdminApi.reducerPath]: selfAdminApi.reducer,
        [domiciliationApi.reducerPath]: domiciliationApi.reducer

    },

    middleware: gDM => gDM()
        .concat(rtkQueryErrorLogger)
        .concat(customerAuthentApi.middleware)
        .concat(pingApi.middleware)
        .concat(featureApi.middleware)
        .concat(gclApi.middleware)
        .concat(beneficiaryApi.middleware)
        .concat(selfCareCardApi.middleware)
        .concat(contractInfoApi.middleware)
        .concat(customerApi.middleware)
        .concat(notificationApi.middleware)
        .concat(paymentProApi.middleware)
        .concat(webProContractApi.middleware)
        .concat(messagingApi.middleware)
        .concat(documentAccessApi.middleware)
        .concat(ihubApi.middleware)
        .concat(publicContactApi.middleware)
        .concat(selfAdminApi.middleware)
        .concat(domiciliationApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
