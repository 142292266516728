import {createApi, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery, encryptDataWithPublicKey} from './api-utils.ts';
import {BglWebAndMobileConfig} from '../../config';
import {
    DevicesResponse,
    GetSignInChallengeRequest,
    LuxtrustChallengeFullCreateResponse,
    LuxtrustFullSignatureChallengeCreationRequest,
    LuxtrustType,
    PhysicalTokenType,
    SignInChallenge,
    WloCardCodeChallenge
} from '../@types/AuthentTypes.ts';

const URL_API = '/business_logon';

export const customerAuthentApi = createApi({
    reducerPath: 'customerAuthentApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    keepUnusedDataFor: 1, /* avoid data caching dor login stuff */
    endpoints: (builder) => ({

        getSignInChallenge: builder.query<LuxtrustChallengeFullCreateResponse, {
            luxTrustType?: LuxtrustType,
            tokenTypeList?: PhysicalTokenType[]
        }>({
            query: ({luxTrustType, tokenTypeList}) => ({
                url: '/public/rest/luxtrust/signin/challenge/get',
                method: 'POST',
                data: {
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                    luxTrustType,
                    tokenTypeList
                }
            })
        }),

        validateSignInChallenge: builder.mutation<any, { challengeId: string, answer: string }>({
            query: ({challengeId, answer}) => ({
                url: '/public/rest/luxtrust/signin/challenge/validate.auth',
                method: 'POST',
                data: {
                    challengeId,
                    answer,
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller')
                }
            })
        }),

        getSignatureChallenge: builder.query<LuxtrustChallengeFullCreateResponse, LuxtrustFullSignatureChallengeCreationRequest>({
            query: ({challengeContext}) => ({
                url: '/rest/signature/challenge/get',
                method: 'POST',
                data: {
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                    challengeContext
                }
            })
        }),

        logout: builder.mutation<void, void>({
            query: () => ({
                url: '/rest/logout/logout.out',
                method: 'GET',
                data: {}
            })
        }),

        createChallenge: builder.mutation<SignInChallenge, void>({
            query: () => ({
                url: '/public/rest/smid/signin/challenge/get',
                method: 'POST',
                data: {
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                }
            })
        }),

        signIn: builder.mutation<Partial<DevicesResponse & SignInChallenge>, GetSignInChallengeRequest>({
            // @ts-ignore
            async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
                const {challengeId, userId, password, pubKey, newPassword} = arg;
                const challengeAnswer = {
                    passwords: [
                        {
                            password,
                            newPassword
                        }
                    ]
                };
                const answer = encryptDataWithPublicKey(challengeAnswer, pubKey, true);

                const signInResult = await fetchWithBQ({
                    url: '/public/rest/smid/signin/challenge/validate.auth',
                    method: 'POST',
                    data: {
                        applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                        answer,
                        challengeId,
                        userId
                    }
                });

                return signInResult.data ? {data: signInResult.data as Partial<DevicesResponse & SignInChallenge>} : {error: signInResult.error as FetchBaseQueryError};

            }
        }),

        validateCardCodeChallenge: builder.mutation<void, { challengeId: string, answer: any, pubKey: string }>({
            query: ({challengeId, answer, pubKey}) => ({
                url: '/rest/lone/smid/signin/authent-up/validate.auth',
                method: 'POST',
                data: {
                    challengeId,
                    answer: encryptDataWithPublicKey(answer, pubKey, true),
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                    connectionType: 'SMID'
                }
            })
        }),

        createCardCodeChallenge: builder.query<WloCardCodeChallenge, { userId: string }>({
            query: ({userId}) => ({
                url: '/getCardCodeChallenge',
                method: 'POST',
                data: {
                    applicationCaller: BglWebAndMobileConfig.get('applicationCaller'),
                    connectionType: 'SMID',
                    userId
                }
            })
        }),

        getAuthentUpChallenge: builder.query<{ cardCodeChallenge: WloCardCodeChallenge }, void>({
            query: () => ({
                url: '/rest/lone/smid/signin/authent-up/get',
                method: 'GET',
                data: {}
            })
        })
    })
});

export const {
    useGetSignatureChallengeQuery,
    useGetSignInChallengeQuery,
    useValidateSignInChallengeMutation,
    useLogoutMutation,

    useCreateChallengeMutation,
    useSignInMutation,
    useValidateCardCodeChallengeMutation,
    useCreateCardCodeChallengeQuery,
    useGetAuthentUpChallengeQuery
} = customerAuthentApi;
