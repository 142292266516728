import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pagination, Skeleton, Stack, Typography} from '@mui/material';
import {useGetAuditLogQuery} from '../../../redux/api/selfAdminApiRtk';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract';
import {
    AuditLog,
    AuditLogFilter,
    AuditLogOrder,
    AuditLogRequest,
    AuditLogResponse
} from '../../../redux/@types/SelfAdminTypes';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import FormattedDateTime from '../../_commons/components/FormattedText/FormattedDateTime';
import TableWithFilter from '../../_commons/components/Table/TableWithFilter';
import {ListFieldType} from '../../_commons/components/Table/AdvancedSearchBox';
import {TableHeaderSorting} from '../../_commons/components/Table/TableHeader';

export type AdvancedFilterKeysType = {
    startDate: any,
    endDate: any,
    dateChecked: boolean,
    type: string,
    typeChecked: boolean,
    source: string,
    sourceChecked: boolean,
    operation: string,
    operationChecked: boolean,
};

const AuditLogsPage = ({items, isFetching}: { items?: AuditLog[], isFetching: boolean }) => {
    const {t} = useTranslation(['commons']);

    return (
        <Grid container rowSpacing={2} alignItems={'flex-start'} width={'100%'}>
            {items?.map(({action, label, timestamp, party}, index) =>
                <Grid container spacing={4} key={index}
                    sx={{
                        borderBottom: '1px solid lightgrey',
                        padding: '1rem 0',
                        width: '100%'
                    }}>
                    <Grid md={2}>
                        <FormattedDateTime dateTime={timestamp} options={{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        }}/>
                    </Grid>
                    <Grid md={2}>
                        <Typography
                            variant={'body1'}>{t(`commons:modules.webpro.self-admin.audit.category.${action}`)}</Typography>
                    </Grid>
                    <Grid md={2}>
                        <Typography
                            variant={'body1'}>{party?.label ?? t('commons:modules.webpro.self-admin.audit.bank')}</Typography>
                    </Grid>
                    <Grid md={6}>
                        <Typography variant={'body1'}>{label}</Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const AuditLogs = () => {
    const {t} = useTranslation(['commons']);

    const listFields = [
        {
            type: 'dateRange',
            field: 'timestamp',
            label: t('commons:modules.webpro.self-admin.audit.header.date'),
            isFilter: false,
            isSortable: true,
            size: {md: 2}
        },
        {
            type: 'select',
            field: 'action',
            label: t('commons:modules.webpro.self-admin.audit.header.type'),
            isFilter: false,
            isSortable: false,
            size: {md: 2}
        },
        {
            type: 'text',
            field: 'party',
            label: t('commons:modules.webpro.self-admin.audit.header.source'),
            isFilter: false,
            isSortable: false,
            size: {md: 2}
        },
        {
            type: 'text',
            field: 'label',
            label: t('commons:modules.webpro.self-admin.audit.header.operation'),
            isFilter: false,
            isSortable: false,
            size: {md: 2}
        }
    ] as Array<ListFieldType>;
    const initialAdvancedKeys: AdvancedFilterKeysType = {
        startDate: null,
        endDate: null,
        dateChecked: false,
        type: '',
        typeChecked: false,
        source: '',
        sourceChecked: false,
        operation: '',
        operationChecked: false
    };
    const initialSorting = {field: 'timestamp', order: 'desc'} as TableHeaderSorting;

    const currentWebProContractReference = useCurrentWebProContractReference();
    const [advancedKeys, setAdvancedKeys] = useState(initialAdvancedKeys);
    const [sorting, setSorting] = useState(initialSorting);

    const [nextPage, setNextPage] = useState(1);

    const buildDocumentQuery = () => {
        const docQuery: AuditLogRequest = {
            paging: {
                pageNumber: nextPage,
                pageSize: 100
            },
            order: {
                orderBy: sorting?.field.toUpperCase() ?? '',
                ordering: sorting.order === 'desc' ? 'DESCENDING' : 'ASCENDING'
            } as AuditLogOrder
        };

        docQuery.filter = Object.fromEntries(Object.entries({
            action: advancedKeys.typeChecked && 'all' !== advancedKeys.type ? {is: advancedKeys.type} : null,
            partyId: advancedKeys.sourceChecked ? {is: t('commons:modules.webpro.self-admin.audit.bank') === advancedKeys.source.toUpperCase() ? 0 : advancedKeys.source} : null,
            label: advancedKeys.operationChecked ? {contains: advancedKeys.operation} : null,
        }).filter(([key, value]) => value !== null)) as unknown as AuditLogFilter;

        return docQuery;
    }

    const {data, isFetching} = useGetAuditLogQuery(buildDocumentQuery(), {skip: !currentWebProContractReference});

    const _handleAdvancedKeys = (advancedKeys: AdvancedFilterKeysType) => {
        setAdvancedKeys(advancedKeys);
    };

    const {
        items,
        pageNumber = 1,
        totalNumberOfItems = 0,
        totalNumberOfPages = 0
    } = data ?? {} as AuditLogResponse;

    const _handleChangePage = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setNextPage(value);
    }, [setNextPage]);

    const getRows = () => {
        return <Stack direction={'column'} spacing={12} justifyContent={'center'} width={'100%'}
                      alignItems={'center'}
                      px={{xs:6, sm: 12}} mb={12} mt={12}>
            <>
                {isFetching || !data ?
                    <Grid container rowSpacing={2} alignItems={'flex-start'} width={'100%'}>
                        <AuditLogsSkeleton/>
                        <AuditLogsSkeleton/>
                        <AuditLogsSkeleton/>
                        <AuditLogsSkeleton/>
                    </Grid>
                    :
                    totalNumberOfItems > 0 ?
                        <>
                            <AuditLogsPage items={items} isFetching={isFetching}/>
                        </>
                        :
                        <>Pas de record</>
                }
            </>

            {totalNumberOfPages > 1 &&
                <Pagination
                    count={totalNumberOfPages}
                    onChange={_handleChangePage}
                    page={pageNumber}
                    color="primary"
                    sx={{display: 'flex', justifyContent: 'center', mb: '10px'}}
                />
            }
        </Stack>
    };

    return (
        <TableWithFilter columns={listFields}
                         sorting={sorting}
                         handleAdvancedKeys={_handleAdvancedKeys}
                         setSorting={setSorting}
                         initialAdvancedKeys={advancedKeys}
                         rows={getRows()}
                         activeFilter={false}/>
    );
};


export const AuditLogsSkeleton = () => {
    return (
        <Stack width="100%" direction={'row'} alignItems={'center'} spacing={8}>
            <Stack flex={1} direction={'row'} spacing={5}>
                <Skeleton animation="wave" height={'1.4rem'} width={'10%'}/>
                <Skeleton animation="wave" height={'1.4rem'} width={'8%'}/>
                <Skeleton animation="wave" height={'1.4rem'} width={'30%'}/>
                <Skeleton animation="wave" height={'1.4rem'} width={'20%'}/>
                <Skeleton animation="wave" height={'1.4rem'} width={'10%'}/>
                <Skeleton animation="wave" height={'1.4rem'} width={'15%'}/>
            </Stack>
        </Stack>
    );
};

export default AuditLogs;
