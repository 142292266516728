import {
    Button,
    Typography
} from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import GenericDialog from '../Dialog/GenericDialog';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import CheckBox from '../Form/Checkbox/CheckBox';
import SelectField, { SelectFieldType } from './AdvancedSearchBoxField/SelectField';
import DateRangeField, { RangeFieldType } from './AdvancedSearchBoxField/DateRangeField';
import TextRangeField from './AdvancedSearchBoxField/TextRangeField';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';

type FieldRange = {
    type: 'date' | 'number',
    label: string,
    field: string
};

export type SelectValueType = {
    label?: string,
    value: string
}

export type ListFieldType = {
    type: 'dateRange' | 'textRange' | 'select',
    fieldFrom?: FieldRange,
    fieldTo?: FieldRange,
    field: string,
    label: string,
    values?: Array<SelectValueType>,
    isFilter: boolean,
    isSortable: boolean,
    size: { [key in Breakpoint]?: number },
    traductionPrefix?: string
}

const AdvancedSearchBox = (
    { title, initialAdvancedKeys, isOpenAdvancedSearch, handleClose, handleAdvancedKeys, listFields }:
        { title: string, initialAdvancedKeys: any, isOpenAdvancedSearch: boolean, handleClose: Function, handleAdvancedKeys: Function, listFields: Array<ListFieldType> }
) => {
    const { t } = useTranslation(['commons']);
    const [advancedKeys, setAdvancedKeys] = useState(initialAdvancedKeys);

    const _checkValue = (e: SyntheticEvent, key: string) => {
        const name = (e.target as HTMLInputElement).id;
        const field = listFields.find(field => field.field === key);

        if (!field) {
            return;
        }

        if (('dateRange' === field.type || 'textRange' === field.type) && !(e.target as HTMLInputElement).checked) {
            setAdvancedKeys({
                ...advancedKeys,
                [name]: (e.target as HTMLInputElement).checked,
                [field.fieldFrom?.field ?? '']: '',
                [field.fieldTo?.field ?? '']: ''
            }
            );
        } else if ((e.target as HTMLInputElement).checked) {
            setAdvancedKeys({ ...advancedKeys, [name]: (e.target as HTMLInputElement).checked });
        } else {
            setAdvancedKeys({ ...advancedKeys, [name]: (e.target as HTMLInputElement).checked, [key]: '' });
        }
    };

    const getLabel = (label: string) => {
        return <Typography
            variant={'h5'}
            marginLeft={0}
            marginTop={'1.5rem'}
            textTransform={'uppercase'}
            marginBottom={'0.5rem'}>
            {label}
        </Typography>
    };

    const getCheckbox = (fieldName: string) => {
        return <CheckBox
            id={fieldName + 'Checked'}
            checked={advancedKeys[fieldName + 'Checked'] as boolean}
            onChange={e => { _checkValue(e, fieldName); }}
            label={''} />
    };

    return (
        <GenericDialog
            title={title}
            handleClose={() => handleClose()}
            isOpen={isOpenAdvancedSearch}
            actions={
                <Button
                    type={'submit'}
                    onClick={() => { handleAdvancedKeys(advancedKeys) }}
                    variant="contained"
                    color="primary"
                    disabled={false}
                    size={'medium'}>{t('commons:buttons.search')}
                </Button>
            }>
            <Box pt={8}>
                {
                    listFields.map((field) => {
                        getLabel(field.label);
                        switch (field.type) {
                            case 'dateRange':
                                return <DateRangeField
                                    advancedKeys={advancedKeys}
                                    field={field as RangeFieldType}
                                    checkbox={getCheckbox(field.field)}
                                    handleAdvancedKeys={setAdvancedKeys} />
                            case 'textRange':
                                return <TextRangeField
                                    advancedKeys={advancedKeys}
                                    field={field as RangeFieldType}
                                    checkbox={getCheckbox(field.field)}
                                    handleAdvancedKeys={setAdvancedKeys} />

                            case 'select':
                                return <SelectField
                                    advancedKeys={advancedKeys}
                                    field={field as SelectFieldType}
                                    checkbox={getCheckbox(field.field)}
                                    handleAdvancedKeys={setAdvancedKeys} />
                            default:
                                return null;
                        }
                    })
                }
            </Box>
        </GenericDialog>
    )
};

export default AdvancedSearchBox;
