import {ERRORS_MATCHING} from '../../../../constants/errors';
import {Slide, toast} from 'react-toastify';
import i18n from 'i18next';
import {Box, Stack} from '@mui/material';

import './toaster.scss';
import ErrorIcon from '../../../../assets/images/icons/toaster/icon-error.svg?react';
import BodyToaster from './BodyToaster';
import CloseButtonToaster from './CloseButtonToaster';

const TOASTER_AUTO_CLOSE = false;
const TOASTER_THEME = 'light';
const TRANSLATION_PATH_LIST = [
    {matchingUrl: 'logon/wlo-war', path: 'authentication:errors.'},
    {matchingUrl: 'business_logon', path: 'authentication:errors.'},
    {matchingUrl: 'business_webmsg', path: 'commons:modules.webpro.messaging.errors.'},
    {matchingUrl: 'business_webpaymentpro', path: 'commons:modules.webpro.payment.errors.'}
];

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    }
}

export const GlobalToaster = {
    success: (title: string, message: string) => {
        return toast.success(<BodyToaster title={title}>{message}</BodyToaster>, {
            position: 'top-center',
            autoClose: TOASTER_AUTO_CLOSE,
            closeButton: CloseButtonToaster,
            toastId: title,
            hideProgressBar: true,
            draggable: false,
            theme: TOASTER_THEME,
            transition: Slide,
            icon: ErrorIcon
        });
    },

    error: (title: string, message: string | string[]) => {
        const getMessages = (messages: string[]) => {
            return messages.map((message) => {
                return <li>{message}</li>;
            })
        }

        const getMessage = () => {
            if (Array.isArray(message)) {
                return <ul>{getMessages(message)}</ul>
            }

            return message;
        }

        return toast.error(<BodyToaster title={title}><Box dangerouslySetInnerHTML={{__html:getMessage()}} /></BodyToaster>, {
            position: 'top-center',
            autoClose: TOASTER_AUTO_CLOSE,
            closeButton: CloseButtonToaster,
            toastId: title,
            hideProgressBar: true,
            draggable: false,
            theme: TOASTER_THEME,
            transition: Slide,
            icon: ErrorIcon
        });
    },

    genericError: (url: string, errorType: string, clientSessionId?: string): number | string | boolean => {
        const translationErrorsPath = ['commons:errors.'];

        const translationMatchingPathByUrl = TRANSLATION_PATH_LIST.find((translationPath) => {
            return url.indexOf(translationPath.matchingUrl) !== -1;
        });

        if (translationMatchingPathByUrl) {
            translationErrorsPath.push(translationMatchingPathByUrl.path);
        }

        // If having error in translate file, don't show the global toaster
        for (const pathError in translationErrorsPath) {
            if (i18n.exists(`${translationErrorsPath[pathError]}${errorType}`)) {
                return false;
            }
        }

        // Find error with url in array of error matching
        const errorMatched = ERRORS_MATCHING.find((error) => {
            return url.match(RegExp(error.urlMatcher, 'i'));
        });

        const code = errorMatched?.code ?? 'R999';
        const title = i18n.t('commons:errors.title') + ' (Code ' + code + ')';
        const msg = i18n.t('commons:errors.technical-error');

        if (code) {
            toast.onChange(async (payload) => {
                switch (payload.status) {
                    case "added":
                        // new toast added
                        break;
                    case "updated":
                        // toast updated
                        break;
                    case "removed":
                        await copyTextToClipboard(clientSessionId ?? '');
                        break;
                }
            });

            return toast.error(<BodyToaster title={title}>
                <Stack spacing={2}>
                    <Box dangerouslySetInnerHTML={{__html:msg}} />
                    {clientSessionId && <Box>Client session id: <b>{clientSessionId}</b></Box>}
                </Stack>
            </BodyToaster>, {
                position: 'top-center',
                autoClose: TOASTER_AUTO_CLOSE,
                closeButton: CloseButtonToaster,
                toastId: code,
                hideProgressBar: true,
                draggable: false,
                theme: TOASTER_THEME,
                transition: Slide,
                icon: ErrorIcon
            });
        }

        return false;
    }
};
