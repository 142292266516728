import React from 'react';
import { Stack } from '@mui/system';
import { FormControl, InputLabel, MenuItem, menuItemClasses, Select, SelectChangeEvent, Typography } from '@mui/material';
import { BasicMenuItem } from '../../../../e-docs/components/AdvancedEdocsSearchBox';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import { friendlyFormatIBAN } from "ibantools";

export type SelectFieldType = {
    type: string,
    field: string,
    label: string,
    labelInput: string,
    values: Array<{ label: string, value: string }>,
    isFilter: boolean,
    isSortable: boolean,
    size: { [key in Breakpoint]?: number }
};

const SelectField = (
    { field, advancedKeys, checkbox, handleAdvancedKeys }:
        { field: SelectFieldType, advancedKeys: any, checkbox: React.ReactElement, handleAdvancedKeys: Function }
) => {
    const _handleFieldChange = (id: string, value: string) => {
        handleAdvancedKeys({
            ...advancedKeys,
            [id + 'Checked']: value && value.length > 0,
            [id]: value,
        });
    };

    const isSelectAccount = field.field.toString() === ('accountBeneficiary')
        || field.field.toString() === ('accountFrom')
        || field.field.toString() === ('debitorAccount')
        || field.field.toString() === ('beneficiaryAccount')
        || field.field.toString() === ('debitAccount');

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={6} mb={4}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>{field.label}</InputLabel>
                <Select
                    value={advancedKeys[field.field] ?? advancedKeys[field.field] ?? ''}
                    label={field.labelInput}
                    fullWidth
                    onChange={(e: SelectChangeEvent) => _handleFieldChange(field.field, e.target.value)}>

                    {field.values.map((value, idx) =>
                        <MenuItem key={value.value} value={value.value} sx={{ [`&.${menuItemClasses['divider']}`]: { borderBottom: '1px dashed #24A668' } }}
                            divider={idx !== field.values.length - 1}>
                            {isSelectAccount &&
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Stack>
                                        <Typography variant={'subtitle2'}>{value.label}</Typography>
                                        <Typography variant={'caption'}>{friendlyFormatIBAN(value.value)}</Typography>
                                    </Stack>
                                </Stack>
                            }
                            {!isSelectAccount &&
                                <BasicMenuItem text={value.label ? value.label : value.value} />
                            }
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

            {checkbox}
        </Stack>
    )
};

export default SelectField;
