import {createApi} from '@reduxjs/toolkit/query/react';
import {
    BeneficiaryList,
    BeneficiaryListRequest,
    CreateOrUpdateBeneficiary,
    DeleteBeneficiaryRequest
} from '../@types/BeneficiaryTypes';
import {axiosBaseQuery} from './api-utils';

const URL_API = '/business_webpaymentpro/rest/beneficiary';

export const beneficiaryApi = createApi({
    reducerPath: 'beneficiaryApi',
    baseQuery: axiosBaseQuery({
        baseUrl: URL_API
    }),
    tagTypes: ['Beneficiaries'],

    endpoints: builder => ({
        getBeneficiaries: builder.query<BeneficiaryList, BeneficiaryListRequest>({
            query: request => ({
                url: '/list',
                method: 'POST',
                data: request
            }),
            providesTags: (result, messages, request) => [
                {type: 'Beneficiaries', id: 'PARTIAL-LIST'},
                {type: 'Beneficiaries', id: request.page}
            ]
        }),
        createBeneficiary: builder.mutation<any, CreateOrUpdateBeneficiary>({
            query: formData => ({
                url: '/create',
                method: 'POST',
                data: formData
            }),
            invalidatesTags: ['Beneficiaries']
        }),
        updateBeneficiary: builder.mutation<any, CreateOrUpdateBeneficiary>({
            query: formData => ({
                url: '/update',
                method: 'POST',
                data: formData
            }),
            invalidatesTags: ['Beneficiaries']
        }),
        deleteBeneficiary: builder.mutation<void, DeleteBeneficiaryRequest>({
            query: request => ({
                url: '/delete',
                method: 'POST',
                data: request
            }),
            invalidatesTags: (result, error, request) => [
                {type: 'Beneficiaries'}
            ]
        }),
    })
});

export const {
    useGetBeneficiariesQuery,
    useCreateBeneficiaryMutation,
    useUpdateBeneficiaryMutation,
    useDeleteBeneficiaryMutation
} = beneficiaryApi;
