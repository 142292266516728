import React from 'react';
import {OutlinedInputProps, styled, TextField} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Chip, {ChipProps} from '../Chip/Chip';
import ArrowRightGreen from '../../../../assets/images/commons/arrow-right-green.svg';

export type ValuesType = {
    label: string,
    value: string,
};

export type LeftAndRightValueTextType = {
    leftValues: Array<ValuesType>,
    rightValues: Array<ValuesType>,
    chipLeft: ChipProps,
    chipRight: ChipProps,
};

const TextFieldWithArrowStyled = styled(TextField)(() => ({
    '&:after': {
        content: '""',
        backgroundImage: `url(${ArrowRightGreen})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'absolute',
        right: '-70px',
        top: '5px',
        width: '40px',
        height: '40px',
    }
}));

const GridLeftValue = styled(Grid)(() => ({
    backgroundColor: '#F8F8F8',
    padding: '30px 50px 30px 30px'
}));
const GridRightValue = styled(Grid)(() => ({
    padding: '30px 30px 30px 50px'
}));

const LeftAndRightValueTextField = ({leftValues, rightValues, chipLeft, chipRight}: LeftAndRightValueTextType) => {

    const ChipValue = (chip: ChipProps) => {
        return <Chip {...chip}
                     typographyProps={{
                         sx: {
                             margin: '2px 5px',
                             textTransform: 'uppercase'
                         }
                     }}
                     sx={{position: 'relative', top: '-40px'}}
        />;
    };

    return (
        <Grid container xs={12}>
            <GridLeftValue xs={6}>
                { chipLeft && ChipValue(chipLeft) }
                {leftValues.map(value => (
                    <TextFieldWithArrowStyled variant="filled" value={value.value} label={value.label} disabled fullWidth InputProps={{ disableUnderline: true} as Partial<OutlinedInputProps>}/>
                ))}
            </GridLeftValue>
            <GridRightValue xs={6}>
                { chipRight && ChipValue(chipRight) }
                {rightValues.map(value => (
                    <TextField variant="filled" value={value.value} label={value.label} fullWidth InputProps={{ disableUnderline: true, readOnly: true} as Partial<OutlinedInputProps>}/>
                ))}
            </GridRightValue>
        </Grid>
    );
};

export default LeftAndRightValueTextField;
