import React, {useEffect, useMemo, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';

import SelfAdminHome from './screens/SelfAdminHome';
import SelfAdminLayout, {
    SelfAdminUserDisplayType,
} from './layout/SelfAdminLayout';
import ManageContract from './screens/ManageContract';
import AuditLogs from './screens/AuditLogs';
import ManagePayments from './screens/ManagePayments';
import ContractUsers from './screens/ContractUsers';
import ContactPerson from './screens/ContactPerson';
import UserDetails from './screens/UserDetails';
import {usePrefetch} from '../../redux/api/selfAdminApiRtk';
import Validation from './screens/Validation';
import {useTranslation} from 'react-i18next';
import CodeSplit from '../../components/CodeSplit';
import {SelfAdminContext} from './context/SelfAdminContext';

const HOMEPAGE = /^\/\w{16}\/[0-9]{6}\/self-admin$/;

const SelfAdminRouter = () => {
    const location = useLocation();
    const {t} = useTranslation(['commons']);
    const isSelfAdminHome = useMemo(
        () => HOMEPAGE.test(location.pathname),
        [location]
    );

    /* Prefetch data for other screens */
    const prefetch = usePrefetch('getContract');
    useEffect(() => {
        prefetch();
    }, [prefetch]);

    const [typeView, setTypeView] = useState(
        'SYNTHESIS' as SelfAdminUserDisplayType
    );
    const contextValue = useMemo(
        () => ({
            typeView,
            setTypeView: (type: SelfAdminUserDisplayType) => {
                setTypeView(type);
            },
        }),
        [typeView]
    );

    return (
        <SelfAdminContext.Provider value={contextValue}>
            <Routes>
                <Route
                    element={
                        <SelfAdminLayout
                            title={t(
                                'commons:modules.webpro.self-admin.labels.contract-management'
                            )}
                            back={!isSelfAdminHome}
                        />
                    }
                >
                    <Route index element={<SelfAdminHome/>}/>
                    {/*<Route path={'payments'} element={<CodeSplit><AuditLogs/></CodeSplit>}/>
                    <Route path={'salary'} element={<CodeSplit><AuditLogs/></CodeSplit>}/>*/}
                </Route>

                <Route
                    element={
                        <SelfAdminLayout
                            title={t(
                                'commons:modules.webpro.self-admin.labels.contract-payment'
                            )}
                            back={!isSelfAdminHome}
                        />
                    }
                >
                    <Route index element={<SelfAdminHome/>}/>
                    <Route
                        path={'payments/:typeList'}
                        element={
                            <CodeSplit>
                                <ManagePayments/>
                            </CodeSplit>
                        }
                    />
                </Route>

                <Route
                    element={
                        <SelfAdminLayout
                            title={t(
                                'commons:modules.webpro.self-admin.labels.contract-update'
                            )}
                            back={!isSelfAdminHome}
                        />
                    }
                >
                    <Route
                        path={'contract'}
                        element={
                            <CodeSplit inner>
                                <ManageContract/>
                            </CodeSplit>
                        }
                    />
                    <Route
                        path={'contact'}
                        element={
                            <CodeSplit inner>
                                <ContactPerson/>
                            </CodeSplit>
                        }
                    />

                    <Route
                        path={'users'}
                        element={
                            <CodeSplit inner>
                                <ContractUsers/>
                            </CodeSplit>
                        }
                    />
                    <Route
                        path={'users/user-detail/:id'}
                        element={
                            <CodeSplit inner>
                                <UserDetails/>
                            </CodeSplit>
                        }
                    />
                </Route>

                <Route
                    element={
                        <SelfAdminLayout
                            title={t(
                                'commons:modules.webpro.self-admin.labels.contract-validation'
                            )}
                            back={!isSelfAdminHome}
                        />
                    }
                >
                    <Route
                        path={'validation'}
                        element={
                            <CodeSplit inner>
                                <Validation/>
                            </CodeSplit>
                        }
                    />
                </Route>

                <Route
                    element={
                        <SelfAdminLayout
                            title={t(
                                'commons:modules.webpro.self-admin.labels.contract-audit'
                            )}
                            back={!isSelfAdminHome}
                        />
                    }
                >
                    <Route
                        path={'audit'}
                        element={
                            <CodeSplit inner>
                                <AuditLogs/>
                            </CodeSplit>
                        }
                    />

                    <Route
                        path={'validation'}
                        element={
                            <CodeSplit>
                                <Validation/>
                            </CodeSplit>
                        }
                    />
                </Route>
            </Routes>
        </SelfAdminContext.Provider>
    );
};

export default SelfAdminRouter;
