import React from 'react';
import {Box, styled} from '@mui/material';

type Color = 'green' | 'orange' | 'red' | 'green-light';

const DashedBox = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'color'
})<{ color: Color }>(({theme, color}) => ({
    borderRadius: '10px',

    ...color === 'green' && {
        border: '1px dashed #9FE0B9',
        boxShadow: '0px 5px 12px #A7C7B31C',
    },
    ...color === 'orange' && {
        border: '1px dashed #ffd8b7',
        boxShadow: '0px 5px 12px #A7C7B31C',
    },
    ...color === 'red' && {
        border: '1px dashed #E8A4A4',
        boxShadow: '0px 5px 12px #AC2B2B1C',
    },

    ...color === 'green-light' && {
        border: '1px dashed #AADCC1'
    }

}));

const DashedPaper = ({color, padding = 'medium', children}: React.PropsWithChildren<{
    color: Color,
    padding?: 'small' | 'medium' | 'large'
}>) => {
    return (
        <Box py={2} px={2} sx={{
            ...color === 'green' && {background: 'transparent linear-gradient(263deg, #F5FFF6 0%, #DEFFE3 100%) 0% 0% no-repeat padding-box;'},
            ...color === 'orange' && {background: 'transparent linear-gradient(263deg, #FFFAF5 0%, #FFF2DE 100%) 0% 0% no-repeat padding-box;'},
            ...color === 'red' && {background: 'transparent linear-gradient(225deg, #FFF3F3 0%, #FFF3F3 100%) 0% 0% no-repeat padding-box;'},
            ...color === 'green-light' && {background: 'transparent linear-gradient(90deg, #E8F6F0 0%, #F2FBF3 100%) 0% 0% no-repeat padding-box;'},
            borderRadius: '10px',
        }}>
            <DashedBox color={color} sx={{
                ...padding === 'small' && {
                    py: 2,
                    px: 2
                },
                ...padding === 'medium' && {
                    py: 4,
                    px: 8
                },
                ...padding === 'large' && {
                    py: 6,
                    px: 12
                }
            }} px={8}>
                {children}
            </DashedBox>
        </Box>
    );
};

export default DashedPaper;
