import React from 'react';
import {Stack, SxProps, Typography} from '@mui/material';
import TableFilterArrow from '../../../_commons/components/Table/TableFilterArrow';
import {Theme} from '@mui/material/styles';

export type SortOrder = 'asc' | 'desc' | null | undefined;

const headerTitleSxProps: SxProps<Theme> = {
    textTransform: 'uppercase',
    color: '#2BAC6B',
    fontFamily: 'BNPPSansCondensed',
    fontSize: '1.1rem',
    fontWeight: '700',
    letterSpacing: '0.12rem'
};

const SortableHeader = ({children, sort, setSorting, field}: React.PropsWithChildren<{ sort: SortOrder, setSorting?: Function, field: string }>) => {
    return (
        <Stack direction={'row'} onClick={() => sort !== undefined && setSorting && setSorting((prevState: any) => ({
            ...prevState,
            order: sort === 'desc' ? 'asc' : 'desc',
            field: field
        }))}>
            <Typography variant={'h4'} textTransform={'uppercase'} sx={headerTitleSxProps}>{children}</Typography>
            {sort !== undefined && <TableFilterArrow sort={sort}/>}
        </Stack>
    );
};

export default SortableHeader;
