import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BglLogger, BglMonitor, BglUserAgentHeaders, PewClientContext} from '@bgl/lib-ts-integration';
// @ts-ignore
import {init} from './config';
import {BglWebAndMobileConfig} from './config';
import UAParser from 'ua-parser-js';
import i18n from './i18n/config';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import {CookiesProvider} from 'react-cookie';
import {Language} from './@types/ui.types';
import Cookies from 'js-cookie';

import {Theme} from './ui/_theme/MuiGlobalTheme';
import {CssBaseline, ThemeProvider} from '@mui/material';


export const rootContainer = document.getElementById('ia-webbanking-pro');

const parser = new UAParser();
const result = parser.getResult();

(async (elem, lng: Language) => {
    (init as () => void)();

    const language = Cookies.get('wbklanguage') as Language ?? lng;

    await BglWebAndMobileConfig.load({
        appIdent: 'WPRO-1.0',
        appName: 'WEB_PRO',
        appLanguage: language,
        appVersion: process.env.REACT_APP_VERSION,
        osVersion: result.os.version,
        osName: result.os.name,
        deviceType: 'COMPUTER',
        deviceName: result.device.model ? `${result.device.vendor}' '${result.device.model}` : result.cpu.architecture,
        applicationCaller: 'WEB_PRO'
    });

    await PewClientContext.init();

    const logger = BglLogger.getInstance();

    BglMonitor.getInstance().setMonitoringDefaultContext(BglUserAgentHeaders.generateHeaders());

    logger.info(`Starting ${VITE_NAME} (${VITE_COMPONENT_ID}) in version ${VITE_VERSION} (Git branch: ${VITE_GIT_BRANCH_NAME}, ${VITE_GIT_COMMIT_HASH})`);

    await i18n.changeLanguage(language);

    if (rootContainer) {

        const root = ReactDOM.createRoot(rootContainer);

        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <CookiesProvider>
                        <ThemeProvider theme={Theme}>
                            <CssBaseline/>
                            <App language={language}/>
                        </ThemeProvider>
                    </CookiesProvider>
                </Provider>
            </React.StrictMode>
        );
    }
})(rootContainer, rootContainer?.dataset.lng as Language ?? 'fr');
