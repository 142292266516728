import React from 'react';
import Chip from '../../_commons/components/Chip/Chip';
import {Stack} from '@mui/material';
import UserIcon from '../../../assets/images/commons/picto/user-standard.svg?react';
import AdminIcon from '../../../assets/images/commons/picto/user-admin.svg?react';
import DisabledIcon from '../../../assets/images/commons/picto/user-disabled.svg?react';
import {useTranslation} from 'react-i18next';

const UserStatusIcon = ({admin = false, decision_maker = false, disabled = false, width = 75, height = 75}: React.PropsWithoutRef<{
    admin?: boolean,
    decision_maker?: boolean,
    disabled?: boolean,
    width?: number,
    height?: number
}>) => {
    const {t} = useTranslation(['commons']);

    return (
        !disabled ?
            <>
                {(admin || decision_maker) &&
                    <Stack spacing={-4} alignItems={'center'}>
                        <AdminIcon width={width} height={height}></AdminIcon>
                        {admin ?
                            <Chip color={'gradient-orange'} title={'ADMIN'} sx={{paddingX: '5px'}}></Chip>
                            :
                            <Chip color={'gradient-orange'} title={t('commons:modules.webpro.self-admin.decision-maker')} sx={{paddingX: '5px'}}></Chip>
                        }
                    </Stack>}
                {!admin && !decision_maker &&
                    <Stack spacing={-4}>
                        <UserIcon width={width} height={height}></UserIcon>
                    </Stack>
                }
            </>
            :
            <Stack spacing={-4}>
                <DisabledIcon width={width} height={height}></DisabledIcon>
                {admin && <Chip color={'grey'} title={'ADMIN'}></Chip>}
            </Stack>
    );
};

export default UserStatusIcon;
