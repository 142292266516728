import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../hooks/useLocale';
import ScreenWrapper from '../../../self-admin/components/ScreenWrapper';
import Chip from '../Chip/Chip';
import { ListFieldType } from './AdvancedSearchBox';
import TableHeader, { TableHeaderSorting } from './TableHeader';

type TableHeaderType = {
    columns: Array<ListFieldType>,
    sorting: TableHeaderSorting,
    handleAdvancedKeys: Function,
    setSorting: Function,
    initialAdvancedKeys: any,
    titleSearchBox?: string | null,
    rows: React.ReactNode,
    activeFilter: boolean,
    handleExport?: Function,
    handlePrint?: Function,
    smallExportButton?: boolean
    smallPrintButton?: boolean
    exportUsage?: string,
    exportTitle?: string,
    slindindPeriodeNbMonths?: number

}

const TableWithFilter = ({ columns, sorting, handleAdvancedKeys, setSorting, initialAdvancedKeys, titleSearchBox, rows, activeFilter = true, handleExport, handlePrint, smallExportButton, smallPrintButton, exportUsage, exportTitle, slindindPeriodeNbMonths }: TableHeaderType) => {
    const { t } = useTranslation(['commons']);
    const locale = useLocale();
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);

    return (
        <ScreenWrapper header={
            <TableHeader
                columns={columns}
                sorting={sorting}
                handleAdvancedKeys={handleAdvancedKeys}
                setSorting={setSorting}
                initialAdvancedKeys={initialAdvancedKeys}
                titleSearchBox={titleSearchBox ?? null}
                openAdvancedSearch={openAdvancedSearch}
                setOpenAdvancedSearch={setOpenAdvancedSearch}
                activeFilter={activeFilter}
                handleExport={handleExport}
                handlePrint={handlePrint}
                smallExportButton={smallExportButton}
                smallPrintButton={smallPrintButton}
                exportUsage={exportUsage}
                exportTitle={exportTitle}
                slindindPeriodeNbMonths={slindindPeriodeNbMonths}
            />
        } height={{ sm: '100px', xs: '100px' }} marginTop={10} stickyTop={'255px'}>
            {activeFilter &&
                <>
                    <Box sx={{ ...({ 'backgroundColor': '#F5F5F5' }) }} border={'1px solid #E1F4EE'} borderBottom={'0'}>
                        <Stack
                            paddingTop={'2.5rem'}
                            direction={'row'}
                            justifyContent={'center'}
                            paddingBottom={'2.5rem'}
                            divider={<Divider orientation="vertical" flexItem />}>
                            <Typography
                                paddingRight={'2rem'}
                                variant={'h3'}>
                                {t('commons:modules.webpro.synthesis.movements.search_results')}
                            </Typography>

                            {activeFilter &&
                                <Button
                                    variant={'text'}
                                    onClick={() => setOpenAdvancedSearch(true)}>
                                    {(t('commons:buttons.edit') as string).toUpperCase()}
                                </Button>
                            }

                        </Stack>
                    </Box>
                    <Stack direction={'row'} justifyContent={'center'} spacing={2} sx={{ marginBottom: '-10px', marginTop: '-15px' }}>
                        {
                            columns.map((field) => {
                                if (initialAdvancedKeys[field.field + 'Checked']) {
                                    let title = '';
                                    switch (field.type) {
                                        case 'dateRange':
                                            title = t('commons:chips.search_date_from_to', {
                                                min: field?.fieldFrom && initialAdvancedKeys[field.fieldFrom.field] ? initialAdvancedKeys[field.fieldFrom.field].toLocaleDateString(locale) : '',
                                                max: field?.fieldTo && initialAdvancedKeys[field.fieldTo.field] ? initialAdvancedKeys[field.fieldTo.field].toLocaleDateString(locale) : ''
                                            });
                                            break;
                                        case 'textRange':
                                            title = t('commons:chips.search_from_to', {
                                                min: field?.fieldFrom && initialAdvancedKeys[field.fieldFrom.field] ? initialAdvancedKeys[field.fieldFrom.field] : '',
                                                max: field?.fieldTo && initialAdvancedKeys[field.fieldTo.field] ? initialAdvancedKeys[field.fieldTo.field] : ''
                                            });
                                            break;
                                        default:
                                            title = initialAdvancedKeys[field.field] ? field.traductionPrefix ? t(`${field.traductionPrefix}${initialAdvancedKeys[field.field]}`, initialAdvancedKeys[field.field]) : initialAdvancedKeys[field.field] : '';
                                            break;
                                    }

                                    return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Chip
                                            typographyProps={{
                                                sx: {
                                                    margin: '4px'
                                                }
                                            }}
                                            title={title}
                                            color="grey" />
                                    </Box>
                                } else {
                                    return <></>
                                }
                            })
                        }
                    </Stack>
                </>
            }
            {rows}
        </ScreenWrapper>
    )
};

export default TableWithFilter;
