import {
    Box,
    Stack,
    FormControl,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {MaxAmount} from '../../../redux/@types/SelfAdminTypes.ts';
import { PaymentTypeType } from '../../../redux/datastore/features/payment/storeSinglePayment.ts';
import { inputLabelClasses } from "@mui/material/InputLabel";
import FormattedAmount from '../../_commons/components/FormattedText/FormattedAmount';
import {useValidation} from '../../../hooks/useValidation';
import AmountTextField from '../../_commons/components/Form/AmountTextField';


const shrankLabelStyle = {sx: {
        [`&.${inputLabelClasses.shrink}`]: {
            backgroundColor: "#EEFFF0",
        }
    }}

const rules = {
    maxAmount: ['numeric']
};

const PaymentLimit = ({
                          maxAmount,
                          paymentType,
                          onChange,
                      }: {
    maxAmount: MaxAmount;
    paymentType: PaymentTypeType.CLASSIC | PaymentTypeType.SALARY;
    onChange: (currentLimitAmount: number | null) => void;
}) => {

    const { t } = useTranslation(['commons']);
    const {validate, setValidationErrors} = useValidation();

    const changePaymentLimit = (value: number|string) => {
        const validation = validate({maxAmount: value}, rules);
        if (validation.fails()) {
            setValidationErrors(validation.errors.all());
        } else {
            setValidationErrors([]);
            onChange(value === '' ? -1 : value as number);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                color: '#00915A',
                backgroundColor: '#EEFFF0',
            }}
        >
            <Stack direction={'column'} spacing={4} alignItems={'center'} m={4}>
                <Typography variant={'h5'} sx={{fontWeight:'medium'}}>
                    {t(
                        `commons:modules.webpro.self-admin.${paymentType}-limit-payments-label`
                    )}
                </Typography>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant={'h5'}>
                        {t(
                            'commons:modules.webpro.self-admin.limit-payement-actual-label'
                        )}{' '}
                    </Typography>
                    {maxAmount?.nb === null || maxAmount?.nb <= 0 ?
                        <Typography variant={'h4'} pl={2} typography={{
                            fontFamily: 'BNPPSansCondensed',
                            fontSize: '1.8rem',
                            fontWeight: '100',
                            color: '#00915A',
                        }}>
                            {t(
                                'commons:modules.webpro.self-admin.limit-payement-unlimited'
                            )}
                        </Typography>
                        :
                        <FormattedAmount
                            typographyProps={{
                                fontFamily: 'BNPPSansCondensed',
                                fontSize: '2rem',
                                fontWeight: '100',
                                color: '#00915A',
                            }}
                            currency={maxAmount.currencyRef}
                            currencyDisplay={'code'}
                            value={maxAmount.nb && maxAmount.nb > 0 ? maxAmount.nb : 0}
                        />
                    }
                </Stack>

                <FormControl sx={{ m: 1, width: 300 }}>
                    <AmountTextField
                        variant='outlined'
                        focused
                        label={t(
                            `commons:modules.webpro.self-admin.${paymentType}-new-limit-payments-label`
                        )}
                        InputLabelProps={shrankLabelStyle}
                        size={'medium'}
                        onChange={(event) =>
                            changePaymentLimit(event.target.value as unknown as number)
                        }
                        value={maxAmount.nb && maxAmount.nb > 0 ? maxAmount.nb : ''}
                        inputProps={{thousandSeparator: true, suffix:' EUR'}}
                />
                </FormControl>
            </Stack>
        </Box>
    );
};

export default PaymentLimit;
