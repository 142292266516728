import React, {useContext} from 'react';
import {Outlet, useMatch} from 'react-router-dom';
import {Box, IconButton, Stack, styled, Typography} from '@mui/material';

import {useTranslation} from 'react-i18next';
import {useAppBarHeight} from '../../../hooks/useAppBarHeight';

import BackImageIcon from '../../../assets/images/commons/back.svg?react';
import {useNavigate} from 'react-router';
import {ConfirmationDialogProvider} from '../../../hooks/ui/useConfirmationDialog';
import {useCurrentWebProContractReference} from '../../../hooks/useCurrentPrimaryContract';
import {useGetContractUpdatesQuery} from '../../../redux/api/selfAdminApiRtk';
import {SelfAdminContext} from '../context/SelfAdminContext';
import ButtonSynthesisDetails from '../components/ButtonSynthesisDetails';

export const TitleStyled = styled(Typography)({
    fontFamily: 'BNPPSansCondensed',
    fontWeight: 100,
    fontSize: '3rem',
    letterSpacing: '0.2rem',
    color: '#FFFFFF',
    textShadow: '0px 2px 6px #161D195A',
    textTransform: 'capitalize',
    lineHeight: 'normal'
});

export type SelfAdminUserDisplayType = 'SYNTHESIS' | 'DETAILS';

const SelfAdminLayout = ({back, title}: React.PropsWithoutRef<{ back: boolean, title?: string }>) => {
    const {t} = useTranslation(['commons']);
    const appBarHeight = useAppBarHeight();
    const navigate = useNavigate();

    const currentWebProContractRef = useCurrentWebProContractReference();
    const {data} = useGetContractUpdatesQuery(void (0), {skip: !currentWebProContractRef});
    const {typeView, setTypeView} = useContext(SelfAdminContext);
    const userView = useMatch('/:webproContract/:primaryContract/self-admin/users');

    return (

        <Stack direction={'column'} width={'100%'} alignItems={'center'}>
            <Stack sx={{
                backgroundImage: `linear-gradient(262deg, #6FD67C 0%, #00915A 100%)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: {
                    xs: '192px',
                    sm: '192px',
                    lg: '192px'
                },

                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                position: 'sticky',
                top: appBarHeight,
                zIndex: 2

            }} direction={'row'}>

                <Stack sx={{
                    width: '100%',
                    pt: 8,
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: {
                        xl: 1070,
                        lg: 1070,
                        md: 700,
                        sm: 550,
                        xs: 500
                    }
                }} direction="column" justifyContent="space-between" position="relative">
                    <Stack direction={{xs:'column', sm: 'row'}} alignItems={'center'} px={{xs:4, sm:0}} >
                        <Stack direction={'row'} alignItems={'center'}>
                            {back &&
                                <Box width={45} height={45} sx={{
                                    boxShadow: '0px 3px 4px #8b7ca340',
                                    border: '1px solid #C6C6C6',
                                    borderRadius: '40px',
                                    background: 'transparent linear-gradient(39deg, #463B50 0%, #717B8B 100%) 0% 0% no-repeat padding-box'
                                }}>
                                    <IconButton onClick={() => navigate(-1)} value={'back'}>
                                        <BackImageIcon/>
                                    </IconButton>
                                </Box>
                            }
                            <Stack px={4}>
                                <TitleStyled
                                    variant={'body1'}>{title ? title : t('commons:modules.webpro.self-admin.title')}</TitleStyled>
                                {data && data.updateNumber && data.updateNumber > 0 ?
                                    <Stack direction={'row'} alignItems={'center'} spacing={4} display={{xs: 'none', sm: 'flex'}}>
                                        <Box borderRadius={25} bgcolor={'#fff'} width={25} height={25} display={'flex'}
                                             alignItems={'center'} justifyContent={'center'}>
                                            <Typography variant={'h5'} fontWeight={400}
                                                        color={'primary'}>{data?.updateNumber}</Typography>
                                        </Box>
                                        <Typography variant={'h5'} fontWeight={100} color={'#fff'}>
                                            {t('commons:modules.webpro.self-admin.updates-waiting-validation', {count: data?.updateNumber ?? 0})}
                                        </Typography>
                                    </Stack>
                                    :
                                    <Typography variant={'h5'} fontWeight={100} color={'#fff'} display={{xs: 'none', sm: 'block'}}>
                                        {t('commons:modules.webpro.self-admin.updates-waiting-validation', {count: 0})}
                                    </Typography>
                                }
                            </Stack>
                        </Stack>
                        {userView &&
                            <Stack marginLeft={'auto !important'}>
                                <Box sx={{width: 'fit-content', whiteSpace: 'nowrap'}}>
                                    <ButtonSynthesisDetails
                                        type={typeView}
                                        handleChange={setTypeView}
                                    />
                                </Box>
                            </Stack>
                        }

                    </Stack>
                </Stack>
            </Stack>

            <Box
                sx={{
                    width: '100%',
                    maxWidth: {
                        xl: 1070,
                        lg: 1070,
                        md: 700,
                        sm: 550,
                        xs: 500
                    }
                }}>
                <ConfirmationDialogProvider>
                    <Outlet/>
                </ConfirmationDialogProvider>
            </Box>
        </Stack>
    );
};

export default SelfAdminLayout;
