export type BeneficiaryList = {
    start: number;
    size: number;
    total: number;
    totalPages: number;
    beneficiaries: Beneficiary[];
};

export type BeneficiaryListRequest = {
    contractReference: string;
    page: number;
    size: number;
    beneficiaryListRequestFilter?: {
        text?: string
        shareBeneficiaryType?: DisplayType;
    }
};

export type DisplayType = 'ALL' | 'PART' | 'IND';

export enum AccountType {
    IBAN = 'IBAN',
    AUTR = 'AUTR'
};

export enum BankCodeType {
    BIC = 'BIC',
    AUTR = 'AUTR'
};

export type CreateOrUpdateBeneficiary = Beneficiary & {
    signatureRequest: {
        challengeId: string,
        answer: string
    }
};

export type Beneficiary = {
    id: string | null;
    contractReference: string;
    beneficiaryBankName: string | null;
    beneficiaryTownBankName: string | null;
    beneficiaryAccountType: AccountType;
    beneficiaryAccountCountryCode: string | null;
    foreignNationalReference: string| null;
    accountReference: string | null;
    bankCodeType?: BankCodeType;
    bankCodeReference: string | null;
    beneficiaryName: string | null;
    beneficiaryAlias: string | null;
    beneficiaryCommunicationArea: string | null;
    shareBeneficiary: boolean;
    beneficiaryAddresses: BeneficiaryAddress[];
    creator: boolean;
    primaryContract: string | null;
};

export type BeneficiaryAddress = {
    id: string;
    streetName: string;
    buildingNumber: string;
    postBox: string;
    postCode: string;
    townName: string;
    countryCode: string;
};

export type DeleteBeneficiaryRequest = {
    contractReference: string;
    id: string;
};
