import {createTheme} from '@mui/material/styles';

import BNPPSansCondensedLight from '../../assets/fonts/bnpp-sans-condensed/bnpp_sans_cond_light_v2-webfont.woff2';
import BNPPSansCondensed from '../../assets/fonts/bnpp-sans-condensed/bnpp_sans_cond_v2-webfont.woff2';
import BNPPSansBold from '../../assets/fonts/bnpp-sans/bnpp-sans-bold.woff';
import BNPPSansLight from '../../assets/fonts/bnpp-sans/bnpp-sans-light.woff';
import BNPPSansRegular from '../../assets/fonts/bnpp-sans/bnpp-sans.woff';

import BNPPRoundedBold from '../../assets/fonts/bnpp-rounded/bnpp-rounded-bold-v2.woff';
import BNPPRoundedExtraBold from '../../assets/fonts/bnpp-rounded/bnpp-rounded-extrabold-v2.woff';
import BNPPRoundedLight from '../../assets/fonts/bnpp-rounded/bnpp-rounded-light-v2.woff';
import BNPPRoundedRegular from '../../assets/fonts/bnpp-rounded/bnpp-rounded-v2.woff';

import {filledInputClasses, formHelperTextClasses, typographyClasses} from '@mui/material';
import {buttonClasses} from '@mui/material/Button';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';
import shadows from '@mui/material/styles/shadows';
import CheckedIcon from '../../assets/images/commons/checked.svg';

import type{} from '@mui/x-date-pickers/themeAugmentation';

type OmitFirst<T extends any[]> = T extends [any, ...infer R] ? R : never;

declare module '@mui/material/styles' {
    interface TypographyVariants {
        cardsubtitle: React.CSSProperties;
        body16: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        cardsubtitle?: React.CSSProperties;
        body16?: React.CSSProperties;
    }

    interface TypeText {
        warning: string;
        accent: string;
    }

}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        cardsubtitle: true;
        body16: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlinedSecondary: true;
    }
     interface ButtonPropsSizeOverrides {
        sizeExtraSmall: true;
     }
}

export const Theme = createTheme({

    // (Bootstrap strategy)
    spacing: (factor: number) => `${0.25 * factor}rem`,

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#00915a'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },

        text: {
            primary: '#464d54',
            warning: '#FF7E1F',
            accent: '#EB4848'
        }
    },

    shadows: [
        'none',
        ...Array(24).fill('0px 3px 6px #0000001A') as OmitFirst<typeof shadows>
    ],

    typography: {
        fontFamily: 'BNPPSans, Arial',
        htmlFontSize: 14,
        fontSize: 14,

        h1: {
            display: 'block',
            fontFamily: 'BNPPSansCondensed',
            textTransform: 'uppercase',
            fontSize: '2.5rem',
            fontVariantLigatures: "no-common-ligatures",
            letterSpacing: '0.075rem'
        },

        h2: {
            fontFamily: 'BNPPSansCondensed',
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: '1.95rem',
            letterSpacing: '0.1rem'
        },

        h3: {
            fontFamily: 'BNPPSansCondensed',
            fontWeight: 100,
            fontSize: '1.75rem',
            textTransform: 'uppercase',
            letterSpacing: '0.075rem'
        },

        h4: {
            fontFamily: 'BNPPSansCondensed',
            fontWeight: 100,
            fontSize: '1.65rem',
            letterSpacing: '0.05rem'
        },

        h5: {
            fontFamily: 'BNPPSansCondensed',
            fontWeight: 100,
            letterSpacing: '.07rem',
            fontSize: '1.4rem'
        },

        h6: {
            /*textAlign: 'center',
            fontFamily: 'BNPPSansCondensed',
            fontSize: '2rem',
            letterSpacing: '1.32px',
            color: '#FFFFFF',
            textShadow: '0px 1px 1px #0000004D',
            textTransform: 'uppercase'*/
            fontFamily: 'BNPPSansCondensed',
            fontWeight: 100,
            letterSpacing: '.07rem',
            fontSize: '1.2rem'
        },

        caption: {
            color: '#848484'
        },

        body1: {
            color: '#464D54',
            fontSize: '0.9rem',
            lineHeight: '1.15rem',
            [`&.${typographyClasses.gutterBottom}`]: {
                marginBottom: '0.7rem'
            }
        },

        body16: {
            color: '#464D54',
            fontWeight: 100,
            fontSize: '1.1rem',
            lineHeight: '1.15rem',
            [`&.${typographyClasses.gutterBottom}`]: {
                marginBottom: '1rem'
            }
        },

        subtitle1: {
            fontSize: '1rem'
        },

        cardsubtitle: {
            fontFamily: 'BNPPSansCondensed',
            fontSize: '1.4rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: '#464D54',
            lineHeight: 1,
            letterSpacing: 'normal'
        }
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: `
            html {
                font-size: 14px;
            }
            
            @font-face {
              font-family: 'BNPPSansCondensed';
              font-weight: 100;
              src: url(${BNPPSansCondensedLight}) format('woff2');
            }
            
            @font-face {
              font-family: 'BNPPSansCondensed';
              font-weight: 400;
              src: url(${BNPPSansCondensed}) format('woff2');
            }
            
            @font-face {
              font-family: 'BNPPRounded';
              font-weight: 100;
              src: url(${BNPPRoundedLight}) format('woff');
            }
            
            @font-face {
              font-family: 'BNPPRounded';
              font-weight: 400;
              src: url(${BNPPRoundedRegular}) format('woff');
            }
            
            @font-face {
              font-family: 'BNPPRounded';
              font-weight: 700;
              src: url(${BNPPRoundedBold}) format('woff');
            }
            
            @font-face {
              font-family: 'BNPPRounded';
              font-weight: 900;
              src: url(${BNPPRoundedExtraBold}) format('woff');
            }
            
            @font-face {
              font-family: 'BNPPSans';
              font-weight: 100;
              src: url(${BNPPSansLight}) format('woff');
            }
            
             @font-face {
              font-family: 'BNPPSans';
              font-weight: 400;
              src: url(${BNPPSansRegular}) format('woff');
            }
            
            @font-face {
              font-family: 'BNPPSans';
              font-weight: 700;
              src: url(${BNPPSansBold}) format('woff');
            }
            `

        },

        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    cardsubtitle: 'div'
                }
            }
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    border: 'none',
                    borderBottom: '1px solid #D3D3D3',
                    boxShadow: '2px 3px 6px #0000001A'
                }
            }
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        background: 'none'
                    }
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    textTransform: 'uppercase',
                    color: '#2BAC6B',
                    backgroundColor: '#FFF',
                    fontFamily: 'BNPPSansCondensed',
                    fontSize: '1.1rem',
                    letterSpacing: '0.12rem'
                },
                filled: {
                    textTransform: 'uppercase',
                    color: '#2BAC6B',
                    [`&.${filledInputClasses['disabled']}`]: {
                        color: '#727E89'
                    }
                },
                root: {
                    [`&.${outlinedInputClasses['error']}`]: {
                        color: '#FF9314'
                    },
                    [`&.${outlinedInputClasses['disabled']}`]: {
                        color: '#2BAC6B'
                    }
                }
            }
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.group-text-field-with-button': {
                        [`&:hover .${outlinedInputClasses['notchedOutline']}`]: {
                            borderRight: 0,
                            borderRadius: '5px 0 0 5px'
                        },

                        [`& > .${outlinedInputClasses['focused']}`]: {
                            [`& > .${outlinedInputClasses['notchedOutline']}`]: {
                                borderRight: 0,
                                borderRadius: '5px 0 0 5px'
                            }
                        }
                    }
                }
            }
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    caretColor: '#2BAC6B',
                    color: '#464D54',

                    [`& .${outlinedInputClasses['notchedOutline']}`]: {
                        borderWidth: 1
                    },

                    [`&:hover .${outlinedInputClasses['notchedOutline']}`]: {
                        borderColor: '#D8D8D8'
                    },

                    [`&.${outlinedInputClasses['focused']}`]: {
                        [`& > .${outlinedInputClasses['notchedOutline']}`]: {
                            border: '1px solid #8DE541',
                            boxShadow: '0 4px 8px #4ADE9415'
                        }
                    },

                    [`&.${outlinedInputClasses['disabled']}`]: {
                        [`& > .${outlinedInputClasses['input']}`]: {
                            WebkitTextFillColor: '#464D54',
                            color: '#464D54'
                        },

                        [`& > .${outlinedInputClasses['notchedOutline']}`]: {
                            borderColor: '#F8F8F8'
                        }
                    },

                    [`&.${outlinedInputClasses['error']}`]: {
                        [`& > .${outlinedInputClasses['notchedOutline']}`]: {
                            borderColor: '#FF9314',
                            boxShadow: '0 4px 8px #FFF3e6',
                            color: '#FF9314'
                        }
                    }

                },

                notchedOutline: {
                    borderColor: '#F8F8F8',
                    '& > legend > span': {
                        letterSpacing: '0.04rem'
                    }
                }
            }
        },

        MuiFilledInput: {
            styleOverrides: {
                input: {
                    textTransform: 'uppercase'
                },
                root: {
                    overflow: 'hidden',
                    borderRadius: 4,
                    border: '1px solid',
                    boxShadow: '0px 1px 3px #6580864E',
                    backgroundColor: 'transparent',
                    marginBottom: '20px',
                    borderColor: '#F8F8F8',
                    color: '#464D54',
                    [`&.${filledInputClasses['focused']}`]: {
                        boxShadow: '0px 1px 3px #6580864E',
                        backgroundColor: 'transparent'
                    },
                    [`&.${filledInputClasses['disabled']}`]: {
                        boxShadow: '0px 1px 3px #6580864E',
                        borderColor: '#E5E5E5',
                        backgroundColor: '#EBEBEB',
                        '&:hover': {
                            backgroundColor: '#EBEBEB'
                        }
                    },
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    [`&.${formHelperTextClasses['error']}`]: {
                        marginTop: '10px',
                        marginRight: '0px',
                        marginLeft: '0px',
                        color: '#FF9314'
                    }

                }
            }
        },

        MuiButton: {

            variants: [
                {
                    props: {variant: 'outlinedSecondary'},
                    style: {
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 4px #A7C7B340',
                        border: '1px solid #C3EBDA',
                        borderRadius: '5px',
                        opacity: 1,
                        //textAlign: 'center',
                        fontFamily: 'BNPPSansCondensed',
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        paddingRight: 25,
                        paddingLeft: 25,

                        color: '#1B985D',

                        '&:hover': {
                            background: '#FFFFFF',
                            boxShadow: '0px 5px 10px #27bf7030 !important'
                        }
                    }
                },
                {
                    props: {variant: 'outlinedSecondary', color: 'error'},
                    style: {
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 4px #A7C7B340',
                        border: '1px solid #EBC3C3',
                        borderRadius: '5px',
                        opacity: 1,
                        textAlign: 'center',
                        fontFamily: 'BNPPSansCondensed',
                        fontSize: '1.1rem',
                        letterSpacing: '1px',
                        color: '#FA7365',
                        textTransform: 'uppercase',

                        '&:hover': {
                            background: '#FFFFFF 0% 0% no-repeat padding-box !important',
                            boxShadow: '0px 5px 10px #27bf7030 !important'
                        }
                    }
                }
            ],
            styleOverrides: {
                root: {
                    borderRadius: 40,
                    textTransform: 'none',
                    boxShadow: '0px 5px 10px #27bf7254',
                    '&.Mui-disabled': {
                        background: 'black linear-gradient(78deg, #94959b 0%, #bebebf 100%) 0% 0% no-repeat padding-box'
                    }

                    /*
                    '&:hover': {
                        boxShadow: '0px 5px 10px #27bf7254',
                        background: 'transparent linear-gradient(78deg, #00a767 0%, #2ab56f 100%) 0% 0% no-repeat padding-box',
                    }
                     */
                },

                containedPrimary: {
                    background: 'transparent linear-gradient(78deg, #018B57 0%, #2BAC6B 100%) 0% 0% no-repeat padding-box',

                    '&:hover': {
                        boxShadow: '0px 5px 10px #27bf7254',
                        background: 'transparent linear-gradient(78deg, #00a767 0%, #2ab56f 100%) 0% 0% no-repeat padding-box'
                    },

                    [`&.${buttonClasses['disabled']}`]: {
                        color: '#fff',
                        boxShadow: 'none',
                        cursor: 'not-allowed'
                    }
                },

                containedSecondary: {
                    background: 'transparent linear-gradient(73deg, #463B50 0%, #717B8B 100%) 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 4px #8B7CA340',

                    [`&.${buttonClasses['disabled']}`]: {
                        color: '#fff',
                        boxShadow: 'none',
                        cursor: 'not-allowed'
                    },

                    [`&:hover`]: {
                        background: 'transparent linear-gradient(73deg, #62586a 0%, #868f9c 100%) 0% 0% no-repeat padding-box'
                    }
                },

                containedError: {
                    background: 'transparent linear-gradient(259deg, #FF6346 0%, #D81811 100%) 0% 0% no-repeat padding-box;',
                    boxShadow: '0 3px 6px #FA475B58',

                    '&:hover': {
                        boxShadow: '0px 3px 6px #FA475B58',
                        background: 'transparent linear-gradient(256deg, #f36c52 0, #d9453f 100%) 0 0 no-repeat padding-box'
                    },

                    [`&.${buttonClasses['disabled']}`]: {
                        color: '#fff',
                        boxShadow: 'none',
                        cursor: 'not-allowed'
                    }
                },

                sizeSmall: {
                    fontSize: '1rem',
                    whiteSpace: 'nowrap',
                    padding: '5px 50px'
                },
                sizeMedium: {
                    fontSize: '1rem',
                    whiteSpace: 'nowrap',
                    padding: '5px 100px'
                },
                sizeLarge: {
                    fontSize: '1.2rem',
                    whiteSpace: 'nowrap',
                    padding: '8px 110px'
                },

                outlined: {
                    '&, &:hover': {
                        border: '2px solid #00915a',
                        background: '#fff'
                    },
                    '&:hover': {
                        background: 'transparent linear-gradient(78deg, #018B5733 0%, #2BAC6B33 100%) 0% 0% no-repeat padding-box'
                    }
                },

                outlinedPrimary: {

                    [`&.${buttonClasses['disabled']}`]: {
                        boxShadow: 'none',
                        opacity: .85,
                        cursor: 'not-allowed'
                    }
                },

                text: {
                    letterSpacing: '.0rem',
                    textDecoration: 'dashed underline 0.05rem',
                    textUnderlineOffset: '0.2rem',
                    textTransform: 'uppercase',
                    boxShadow: 'none',
                    font: 'normal normal normal 0.2rem/0.3rem BNPPSans',
                    color: '#1B985D',

                    '&:hover': {
                        boxShadow: 'none',
                        background: 'transparent',
                        textDecoration: 'dashed underline 0.05rem',
                        textUnderlineOffset: '0.2rem'
                    }
                },

                disabled: {}

            }

        },

        MuiCard: {
            styleOverrides: {
                root: {
                    border: '1px solid #EBEBEB',
                    boxShadow: '0px 5px 12px #0000000D',
                    borderRadius: 5,
                    overflow: 'visible',
                    marginBottom: '17px'
                }
            }
        },

        MuiCardActions: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    bottom: '-26px'
                }
            }
        },

        MuiDivider: {
            styleOverrides: {
                light: {
                    backgroundColor: 'rgba(112, 112, 112, 0.1)'
                }
            }
        },

        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: 'transparent'
                }
            }
        },

        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center'
                }
            }
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    border: '1px solid #EFEFEF'
                },
                elevation0: {
                    border: 'none'
                }
            }
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    boxShadow: '0 2px 5px #f1fafb',
                    '&.Mui-disabled': {
                        boxShadow: '0 2px 5px #f1fafb'
                    },
                    '&:hover [disabled] ~ .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#F8F8F8'
                    },
                    '&.Mui-focused': {
                        boxShadow: '0 4px 8px #4ADE9415'
                    }
                }
            }
        },

        MuiSelect: {
            styleOverrides: {
                select: {
                    background: 'none',
                    fontSize: 'inherit',
                    fontWeight: 'bold',
                    lineHeight: 'inherit',
                    fontFamily: 'BNPPSansCondensed',
                    minHeight: 'inherit',
                    //padding: '4px',
                    //color: '#1B985D',
                    textTransform: 'uppercase',

                    ' &:before': {
                        borderStyle: 'dashed'
                    }
                }
            }
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'BNPPSansCondensed',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '0.03rem',
                    padding: '0.9rem',
                    paddingRight: '40px', /* reserve space for selected icon */

                    '&.Mui-selected': {
                        color: '#1B985D',

                        '&:after': {
                            position: 'absolute',
                            content: `url(${CheckedIcon})`,
                            right: '10px'
                        }
                    }
                }
            }
        },

        MuiDialog: {
            styleOverrides: {
                root: {
                    width: '-webkit-fill-available',
                    height: '-webkit-fill-available'
                }
            }
        },

        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: '2rem'
                },

                colorPrimary: {
                    color: '#2bac6b'
                },

                colorDisabled: {
                    color: '#c0c4c1'
                }
            }
        },

        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.MuiListItem-divider&:last-child': {
                        borderBottom: 'none'
                    }
                }
            }
        },

        MuiSpeedDial: {
            styleOverrides: {
                root: {
                    '.MuiSpeedDial-fab': {
                        background: 'transparent linear-gradient(45deg, #049445 0%, #71D442 100%) 0% 0% no-repeat padding-box',
                        boxShadow: '0px 10px 25px #19AF5041',
                        border: '4px solid white',
                        '&[aria-expanded="true"]': {
                            background: 'transparent linear-gradient(45deg, #F75D67 0%, #F3937D 100%) 0% 0% no-repeat padding-box'
                        },
                        width: '70px',
                        height: '70px'
                    },
                    '.MuiSpeedDial-actions': {
                        marginBottom: '0',
                        paddingBottom: '0',
                        alignItems: 'end'
                    },
                    '.MuiSpeedDialAction-fab': {
                        background: 'white',
                        border: '1px solid #C3C3C3',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '20px',
                        width: '100%',
                        padding: '2px 5px',
                        minHeight: 'auto',
                        height: 'auto',
                        '& > a': {
                            textDecoration: 'none'
                        }
                    }
                }
            }
        },

        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 5px 12px #A7C7B329',
                    borderRadius: '12px'
                },
                grouped: {
                    '&:last-of-type': {
                        borderTopRightRadius: '12px',
                        borderBottomRightRadius: '12px'
                    },
                    '&:first-of-type': {
                        borderTopLeftRadius: '12px',
                        borderBottomLeftRadius: '12px'
                    }
                }
            }
        },

        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    fontFamily: 'BNPPSansCondensed',
                    fontSize: '1.2rem',
                    color: '#464D54',
                    textShadow: '0px 1px 1px #00000029',
                    textTransform: 'uppercase',
                    padding: '10px',
                    borderRadius: '12px',

                    '&.Mui-selected': {
                        background: 'transparent linear-gradient(255deg, #29AA6A 0%, #018B57 100%) 0% 0% no-repeat padding-box',
                        boxShadow: '0px 0px 8px #6CDB9566',
                        borderRadius: '12px',
                        color: '#fff'
                    },
                },

            }
        },

        MuiStepper: {},

        MuiBadge: {
            styleOverrides: {
                colorWarning: {
                    fontFamily: 'BNPPSansCondensed',
                    fontWeight: 700,
                    fontSize: '1.15rem',
                    color: '#464d54',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0 0 10px #FAA74767',
                    border: '2px solid #FFC980',
                    borderRadius: '23px'
                }
            }
        },

        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    display: 'block'
                }
            }
        }
    }
});
