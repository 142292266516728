import React, {PropsWithChildren, SyntheticEvent} from 'react';
import {ButtonBase, SxProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';

const Pressable = ({children, sx, to, onClick}: PropsWithChildren<{
    to?: string,
    onClick?: (e: SyntheticEvent) => void,
    sx?: SxProps<Theme>
}>) => (
    <ButtonBase sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 5px 12px #A7C7B31C',
        border: '1px solid #E1F4EE',
        borderRadius: '15px',
        ...sx
    }} component={RouterLink} to={to ?? ''} onClick={onClick}>
        {children}
    </ButtonBase>
);

export default Pressable;
